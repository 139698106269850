import { IS_MOBILE, SLIDE_DELAY } from '../utils/globals';

const $footerNavs = $('.js-footer-nav');
const $footerCols = $('.js-footer-cols');

let $headings = $footerCols.find('h5');

if (IS_MOBILE) {
	$headings.on('click', function() {
		const $currentNav = $(this).next('.js-footer-nav');
		$currentNav.toggleClass('is-active');

		if ($currentNav.hasClass('is-active')) {
			$currentNav.slideDown(SLIDE_DELAY);
		} else {
			$currentNav.slideUp(SLIDE_DELAY);
		}
	});
}

