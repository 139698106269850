import { $win } from '../utils/globals'
import Isotope from 'isotope-layout';
let loadingAjax = false;

$(window).on('scroll', function(){
	if ($('.journals').length) {
		if( $(window).scrollTop() >= $('.journals').offset().top + $('.journals').outerHeight() - window.innerHeight ) {
			loadJournal();
		}
	}
});

const loadJournal = function () {
	if ( ! loadingAjax ) {
		loadingAjax = true;

		let $loadMore = $('.js-journal-load-more');
		let url = $loadMore.attr('href');

		if ( $loadMore.length ) {
			$.ajax({
				url: url,
				beforeSend: function(){
				},
				success: function(response){
					let $container = $('.journals__items');
					let $loadMoreNew = $('.js-journal-load-more');
					let $newArticles = $(response).find('.journals__items .journals__item');
					let $newLoadMore = $(response).find('.js-journal-load-more');

					$loadMoreNew.remove();

					if ($newArticles.length) {
						$container.append($newArticles);
					}

					if ($newLoadMore.length) {
						$container.after($newLoadMore);					
					}
				},
				error: function(error){
					console.log(error);
				},
				complete: function(){
					loadingAjax = false;
					const items = document.querySelector('.journals__items');

					const isotope = new Isotope( items, {
						itemSelector: '.journals__item',
					});
				}
			})
		}
	}
}
