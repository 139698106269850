import Swiper from 'swiper/swiper-bundle.js';
import { $win } from '../utils/globals';

const $sliders = $('.js-slider');

$sliders.each(function(i, currentSlider) {
	const $currentParent = $(currentSlider).parent();

	new Swiper(currentSlider, {
		slidesPerView: 'auto',
		spaceBetween: 35,
		freeMode: true,
		speed: 1000,
		breakpoints: {
			320: {
				spaceBetween: 13,
			},
			575: {
				spaceBetween: 20,
			},
			767: {
				spaceBetween: 30,
			},
			1023: {
				spaceBetween: 40,
			},
			1439: {
				spaceBetween: 36,
			},
			1919: {
				spaceBetween: 64
			},
		},
	});
});

$('.js-slider-members .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-members');

    const swiper = new Swiper(slider, {
        slidesPerView: 'auto',
		spaceBetween: 35,
		freeMode: true,
		speed: 1000,
		breakpoints: {
			320: {
				spaceBetween: 13,
			},
			575: {
				spaceBetween: 20,
			},
			767: {
				spaceBetween: 30,
				slidesPerView: 3,
			},
			1023: {
				spaceBetween: 40,
			},
			1439: {
				spaceBetween: 42,
				slidesPerView: 4,
			},
			1919: {
				spaceBetween: 64,
				slidesPerView: 'auto',
			},
		},
    });
});

$('.js-slider-images .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-images');
    const nextEl = $slider.find('.slider__btn--next');
    const prevEl = $slider.find('.slider__btn--prev');

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        //autoHeight: true,
        resistanceRatio: 0,
        watchOverflow: true,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
        },
    });
});

$('.js-slider-events .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-events');
    const sliderPagination = $slider.find('.slider__paging')[0];

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        resistanceRatio: 0,
        watchOverflow: true,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
		autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
		effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        pagination: {
            el: sliderPagination,
            clickable: true,
        },
    });
});

$('.js-slider-membership .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-membership');
    const sliderPagination = $slider.find('.slider__paging')[0];

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        autoHeight: true,
        resistanceRatio: 0,
        //watchOverflow: true,
        spaceBetween: 30,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: sliderPagination,
            clickable: true,
        },
    });
});

$('.js-slider-slideshow .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-images');

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        //autoHeight: true,
        resistanceRatio: 0,
        watchOverflow: true,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
    });
});

$('.js-slider-testimonials .swiper-container').each((i, slider) => {
    const $slider = $(slider).closest('.js-slider-testimonials');
    const nextEl = $slider.find('.slider__btn--next');
    const prevEl = $slider.find('.slider__btn--prev');

    const swiper = new Swiper(slider, {
        slidesPerView: 1,
        //autoHeight: true,
        resistanceRatio: 0,
        watchOverflow: true,
        spaceBetween: 0,
        loop: true,
        speed: 1000,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
        },
    });
});

const cursor = $('.js-slider-testimonials .slider__cursor');

$win.mousemove(function(e) {
    cursor.css({
        top: e.clientY - cursor.height() / 2,
        left: e.clientX - cursor.width() / 2
    });

    if ( $('.js-slider-testimonials .slider__prev').is(e.target) ) {
        $('.js-slider-testimonials').addClass('has-prev-arrow')
    } else {
        $('.js-slider-testimonials').removeClass('has-prev-arrow')
    }

    if ($('.js-slider-testimonials .slider__next').is(e.target)) {
        $('.js-slider-testimonials').addClass('has-next-arrow')
    } else {
        $('.js-slider-testimonials').removeClass('has-next-arrow')
    }
});



