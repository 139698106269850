/*
 * Modules
 */

import './modules/sliders';
import './modules/subscribe-focus';
import './modules/accordion';
import './modules/membership-dropdown';
import './modules/navbar';
import './modules/footer-navbar';
import './modules/header';
import './modules/cta';
import './modules/popup';
import './modules/selectric';
import './modules/colective-form';
import './modules/load-more';
import './modules/gravity-forms';
import './modules/remove-empty-elements';
import './modules/js-background';
import './modules/fixed-elements';
import './modules/isotope';
import './modules/filter-dropdown';
import './modules/load-journal';
import './modules/duplicate-content';
import './modules/marquee-elements';
import './modules/section-image-and-content';
import './modules/videos';
import './modules/scroll-to-section';
