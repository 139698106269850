import { $win, $body, $doc } from '../utils/globals';

const $popupGallery = $('.popup-gallery');
const $popupGalleryBody = $('.popup-gallery .popup__body');
const $popupGalleryCaption = $('.popup-gallery .popup__caption');
const $popupVideo = $('.popup-video');
const $popupVideoPlayer = $('.popup-video .popup__video');


function showPopup($popup, $popupContent) {
	$popup.css({
		'opacity': '1',
		'visibility': 'visible'
	});


	$popupContent.css('transform', 'scale(1)');
}

function hidePopup($popup, $popupContent) {
	$popup.css({
		'opacity': '0',
		'visibility': 'hidden'
	});

	$popupContent.css('transform', 'scale(0)');

	setTimeout(function() {
		$popup.find('.popup__body img').remove();
	}, 400)
}

function changePopupGalleryImg(imgSrc, imgCaption) {
	if (imgSrc) {
		if (! $popupGalleryBody.find('img').length) {
			$popupGalleryBody.prepend('<img src="">');
		}

		$popupGalleryBody.find('img').attr('src', imgSrc);
	}

	if (imgCaption) {
		$('.popup-gallery .popup__caption p').text(imgCaption);
	}
}


/*
	-- Gallery Popup Show
*/
$('.gallery-default .gallery__item').on('click', function() {
	$body.addClass('show-popup-gallery');

	changePopupGalleryImg($(this).data('url'), $(this).data('caption'))

	setTimeout(function() {
		showPopup($popupGallery, $popupGalleryBody.find('img'));
	}, 100)
});

/*
	-- Gallery Popup Hide / Switch IMG
*/
$doc.on('click', function(e) {
	if (! $body.is('.show-popup-gallery')) {
		return;
	}
	e.preventDefault();

	const $eTarget = $(e.target);

	if ($eTarget.is('.popup-gallery .popup__btn') || $eTarget.is('.popup-gallery .popup__inner') || $eTarget.is('.popup-gallery .popup__body')) {

		hidePopup($popupGallery, $popupGalleryBody.find('img'));

		$body.removeClass('show-popup-gallery');
	} else if ($eTarget.is('.popup__action')) {
		const $currentImgSrc = $popupGalleryBody.find('img').attr('src');
		const $currentCaptionContainer = $popupGallery.find('.popup__caption p');
		const $currentImgGalleryItem = $(`[data-url="${$currentImgSrc}"]`);
		let $newImgSrc;
		let $newImgCaption;

		if ($eTarget.is('.popup__action--next')) {
			const $nextGalleryItem = $currentImgGalleryItem.parent().next();

			if ($nextGalleryItem.length) {
				$newImgSrc = $nextGalleryItem.find('.gallery__item').data('url');
				$newImgCaption = $nextGalleryItem.find('.gallery__item').data('caption');
			} else {
				$newImgSrc = $currentImgGalleryItem.closest('ul').find('.gallery__item').first().data('url');
				$newImgCaption = $currentImgGalleryItem.closest('ul').find('.gallery__item').first().data('caption');
			}
		} else {
			const $prevGalleryItem = $currentImgGalleryItem.parent().prev();

			if ($prevGalleryItem.length) {
				$newImgSrc = $prevGalleryItem.find('.gallery__item').data('url');
				$newImgCaption = $prevGalleryItem.find('.gallery__item').data('caption');
			} else {
				$newImgSrc = $currentImgGalleryItem.closest('ul').find('.gallery__item').last().data('url');
				$newImgCaption = $currentImgGalleryItem.closest('ul').find('.gallery__item').last().data('caption');
			}
		}

		changePopupGalleryImg($newImgSrc, $newImgCaption);
	}
});


/*
	-- Video Popup Show
*/

$('[href="#popup-video"]').on('click', function() {
	$body.addClass('show-popup-video');

	$popupVideo.find('iframe').attr('src', $(this).data('url'));

	setTimeout(function() {
		showPopup($popupVideo, $popupVideoPlayer);
	}, 200)
});

$doc.on('click', function(e) {
	if (! $body.is('.show-popup-video')) {
		return;
	}
	e.preventDefault();

	const $eTarget = $(e.target);

	if ($eTarget.is('.popup-video .popup__btn') || $eTarget.is('.popup-video .popup__inner') || $eTarget.is('.popup-video .popup__body')) {

		hidePopup($popupVideo, $popupVideoPlayer);

		$body.removeClass('show-popup-video');
		$popupVideo.find('iframe').attr('src', '')
	}
});


let $deviceWidth = $(document).width();

if ( $deviceWidth <= 1023 ) {
	$('.js-popover a').on('click', function(e) {
		let $content = $(this).siblings('.popover-content');
		e.preventDefault();

		if ( $content.hasClass('is-active') ) {
			$content.css('opacity', '0.0');
		} else {
			$content.css('opacity', '1.0');
		}

		$content.toggleClass('is-active');

	});
}

