$('.js-video').each(function (i, elem) {
	const $videoDefaultContainer = $(elem);
	const playButton = $(elem).find('.js-play-video');
	const video = $videoDefaultContainer.find('video');

	playButton.on('click', function(e) {
		e.preventDefault();

		video.trigger('play');

		$videoDefaultContainer.addClass('is-active');
	});

	video.on('play', function(e) {
		$(this).attr('controls','true');
	});

	video.bind('ended', function() {
		$videoDefaultContainer.removeClass('is-active');
		
		$(this).removeAttr('controls');

		console.log(2);
	});
});

const $videos = $('.js-video-vimeo');

$videos.each(function(i, el) {
	const $this = $(this);
	const $btn = $this.find('.js-play-vimeo');
	const $placeholder = $this.find('.video__placeholder');
	const $videoHolder = $this.find('.video__inner');
	const $buttonHolder = $this.find('.video__btn');

	let player = new Vimeo.Player($videoHolder, {
		id: $videoHolder.data('video-id')
	});

	$btn.on('click', function(event) {
		event.preventDefault();

		$btn.addClass('is-playing');
		$placeholder.addClass('is-playing');
		$videoHolder.addClass('is-playing');
		$buttonHolder.addClass('is-playing');
		player.play();
	})

	player.on('ended', function() {
		const $iframe = $(this.element);
		$placeholder.removeClass('is-playing');
		$btn.removeClass('is-playing');
	});
})

