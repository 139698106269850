import { $win } from '../utils/globals';

$win.on('load', function() {
	let offsets = [];

	$('.js-background').each((index, elem) => {
		$(elem).attr('data-id', `js-background-${index}`);

		offsets.push([$(elem).offset().top, $(elem).data('color'), $(elem).data('id')]);
	});

	if (offsets.length < 1) {
		return;
	}

	$('.wrapper').css('background-color', offsets[0][1]);

	var lastScrollTop = 0;
	window.addEventListener("scroll", function(){
		var st = window.pageYOffset || document.documentElement.scrollTop;

		if (st > lastScrollTop) {
			// downscroll code
			offsets.forEach((elem) => {
				if ($win.scrollTop() >= elem[0] - $win.outerHeight() + 300) {
					$('.wrapper').css('background-color', elem[1]);
				}
			});
		} else {
			// upscroll code
			offsets.forEach((elem) => {
				if ($win.scrollTop() >= elem[0] - 300) {
					$('.wrapper').css('background-color', elem[1]);
				}
			});
		}

		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	}, false);
});
