const $win = $(window);
const $doc = $(document);
const $body = $('body');
const $root = $('html, body');
const IS_MOBILE = $win.width() < 768;
const SLIDE_DELAY = 300;

export {
	$win,
	$doc,
	$body,
	$root,
	IS_MOBILE,
	SLIDE_DELAY,
};
