import { $win, $body } from '../utils/globals';

const HEADER_HEIGHT = $('.header').outerHeight();

$win.scroll(function() {
	$body.toggleClass('header-is-colored', $win.scrollTop() > HEADER_HEIGHT);
});

// $(window).on( 'scroll', function () {
// 	if ( $('body').hasClass('header-is-colored') ) {
// 		$('.header__logo img').attr('src', 'dist/images/svg/logo-main-black.svg' );
// 	}
// })
