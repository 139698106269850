import { $doc } from '../utils/globals'

$doc.on('gform_post_render', function(event, formId){
 	$('.apply_wrapper .gfield input:not(:radio), .apply_wrapper .gfield input:not(:checkbox) .apply_wrapper .gfield textarea').each( (i, element) => {
 		const val = $(element).val();
 		if (val.length) {
 			$(element).closest('.gfield').addClass('hide-label');
 		}
 	});

 	$('.apply_wrapper .gfield input:not(:radio), .apply_wrapper .gfield input:not(:checkbox) .apply_wrapper .gfield textarea').on({
 		focus() {
 			$(this).closest('.gfield').addClass('hide-label');
 		},
 		blur(){
 			const val = $(this).val();
 			if (!val) {
 				$(this).closest('.gfield').removeClass('hide-label');
 			}
 		}
 	})
});
