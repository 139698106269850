import { $document, $win } from '../utils/globals'

$('.js-open-filters').on('click', function(e){
	if( $win.width() < 768 ) {
		e.preventDefault();
		const $targetElement = $(this).closest('.section-journal')
		const $targetFilters = $(this).closest('.section-journal').find('.section__filters ul');

		$targetElement.toggleClass('has-filters-opened');

		$targetFilters.slideToggle();
	}
});

$('.js-open-filters').on('click', function(e){
	if( $win.width() < 768 ) {
		e.preventDefault();
		const $targetElement = $(this).closest('.section-events')
		const $targetFilters = $(this).closest('.section-events').find('.section__filters ul');
		const $targetHeading = $(this).closest('.section-events').find('.section__filters > h1');

		$targetElement.toggleClass('has-filters-opened');

		$targetFilters.slideToggle();
		$targetHeading.slideToggle();
	}
});

$('.js-dropdown-links').on('click', function(e){
	e.preventDefault();
	const $targetElement = $(this).closest('.dropdown-links')
	const $targetDropdown = $(this).closest('.dropdown-links').find('.dropdown__list');

	console.log($targetElement);

	$targetElement.toggleClass('has-list-opened');

	$targetDropdown.slideToggle();
});

$(document).on('click', '.js-open-event-filters', function(e){
	e.preventDefault();
	const $targetElement = $(this).closest('.filter')
	const $targetFilters = $(this).closest('.filter').find('.filter__list');

	$targetElement.toggleClass('has-filters-opened');

	$targetFilters.slideToggle();
});
