import { $win, $body } from '../utils/globals'


$('.section-journal').each(() => {
	$win.on('scroll', function() {
		const $targetSection = $('.section-journal');

		if ($(this).scrollTop() >= $targetSection.position().top) {
			$body.addClass('has-fixed-inner-bar');
		} else {
			$body.removeClass('has-fixed-inner-bar');
		}
	});
});

$('.section-events').each(() => {
	$win.on('scroll', function() {
		const $targetSection = $('.section-events');

		if ($(this).scrollTop() >= $targetSection.position().top) {
			$body.addClass('has-fixed-inner-bar');
		} else {
			$body.removeClass('has-fixed-inner-bar');
		}
	});
});

