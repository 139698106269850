import { SLIDE_DELAY } from '../utils/globals';
import '../utils/equalizeHeight';

const $memberships = $('.js-membership');
const $membershipTriggers = $('.js-trigger', $memberships);

/**
 * Trigger clicked membership is-active class
 * @param  {Event} e
 */
$membershipTriggers.on('click', function(e) {
	e.preventDefault();

	const $currentMembership = $(this).parents('.js-membership');
	const $currentContent = $('.js-hidden', $currentMembership);

	$currentMembership.toggleClass('is-active');

	if ( $currentMembership.hasClass('is-active') ) {
		const $triggerSpan = $currentMembership.find('.js-trigger span');
		$triggerSpan.text('Hide Benefits');

		$currentMembership.find('.js-hidden').slideDown(SLIDE_DELAY);
	} else {
		const $triggerSpan = $currentMembership.find('.js-trigger span');
		$triggerSpan.text('View Benefits');

		$currentMembership.find('.js-hidden').slideUp(SLIDE_DELAY);
	}
});

