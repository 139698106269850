/*
 * Internal dependencies.
 */

import { $win, $root } from '../utils/globals';

const winLochash = window.location.hash;

/**
 * Initializes Scroll By Data.
 */

function scrollByData(data) {
    const $headerHeight = $('.header').outerHeight();

    if (!data) {
        return false;
    }

    const target = data.replace('#', '');
    const $targetElement = $(`*[id="${target}"]`);

    if ($targetElement.length > 0) {
        $root.animate({
            scrollTop: $targetElement.offset().top - $headerHeight
        }, 500, function() {
            $targetElement.addClass('is-active').find('.accordion__body').slideDown();

            $targetElement.siblings().removeClass('is-active')
                .find('.accordion__body').slideUp();
        });
    }
}

/**
 * Handle Links Click.
 */

$('a.js-scroll-to-section').each(function () {
    const $this = $(this);
    const href = this.hash;

    if (href) {
        $(this).on('click', function(event) {
            scrollByData( href );
        });
    }
});

/**
 * Handle Window Load.
 */

$win.on('load', function() {
    if ( winLochash.length > 0 ) {
        $root.scrollTop(0, 0);
        setTimeout(function() {
            scrollByData( winLochash );
        }, 10);
	}
});
