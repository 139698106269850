const eventArgs = {
	paged: 1,
	location: null,
	event_type: null,
};

$(document).ready(function() {
	$('.section__events:not(:first)').addClass('hidden');
	$('.section__events:eq(1)').removeClass('hidden');
});

$(window).on('scroll', function(){
	if( $(window).scrollTop() >= $('.main').offset().top + $('.main').outerHeight() - window.innerHeight ) {
		$('.hidden').first().removeClass('hidden');
	}

	if ( $('.section-events--past').length > 0 ) {
		if( $(window).scrollTop() >= $('.section-events--past').offset().top + $('.section-events--past').outerHeight() - window.innerHeight ) {
			$('.hide_event:lt(3)').removeClass('hide_event');
		}
	}
});

$(document).on('click', '.js-load-more-events', function(e) {
    e.preventDefault();

    const $this = $(this);

    $.ajax({
        url: CRB_Vars.ajaxUrl,
		data: {
			...eventArgs,
			action: 'crb_get_events',
			paged: $(this).attr('data-paged'),
			is_past: true,
		},
        beforeSend: function() {
            $('body').addClass('ajax-is-loading');
        },
        success: function(response) {
            const $events = $(response).find('.js-events .accordion__section');
            const $loadMore = $(response).find('.js-load-more-events');

            if ( $events.length ) {
                $('.js-events').append($events);
            }

            if ( $loadMore.length ) {
                $this.attr('data-paged', $loadMore.attr('data-paged'));
            } else {
                $this.parent().fadeOut().remove();
            }
        },
        complete: function() {
            $('body').removeClass('ajax-is-loading');
        },
    });
});



$(document).on('click', '.js-event-filters a', function(e){
	e.preventDefault();

	if ( typeof $(this).attr('data-location') !== 'undefined' ) {
		eventArgs.location = $(this).attr('data-location');
	} else if ( typeof $(this).attr('data-event-type') !== 'undefined' ) {
		eventArgs.event_type = $(this).attr('data-event-type');
	}

	const $this = $(this);

    $.ajax({
        url: CRB_Vars.ajaxUrl,
		data: {
			...eventArgs,
			action: 'crb_get_events',
			return_both: true,
		},
        beforeSend: function() {
            $('body').addClass('ajax-is-loading');
        },
        success: function(response) {
            // const $filters = $(response).find('.js-all-events-filters');
			const $upcoming_events = $(response).find('.js-upcoming-events');
			const $past_events = $(response).find('.js-past-events');
			$this.parent().addClass('is-active').siblings().removeClass('is-active');

            // $('.main .js-all-events-filters').html($filters.html());
            $('.main .js-upcoming-events').html($upcoming_events.html());
            $('.main .js-past-events').html($past_events.html());
        },
        complete: function() {
            $('body').removeClass('ajax-is-loading');
        },
    });
});
