const $accordion = $('.js-faq-accordion');
const $accordionSections = $('.accordion__section', $accordion);
const $accordionBodies = $('.accordion__body', $accordion);
const $accordionTriggers = $('.accordion__head a', $accordion);
const SLIDE_DELAY = 300;

/**
 * Close all sections
 * @return {undefined}
 */
const closeSections = () => {
	const $accordionSections = $('.js-faq-accordion .accordion__section');
	const $accordionBodies = $('.js-faq-accordion .accordion__body');

	if (!$accordionSections.length) return;

	$accordionSections.removeClass('is-active');
	$accordionBodies.slideUp(SLIDE_DELAY);
};

/**
 * Open clicked section
 * @param  {Event} e
 * @return {undefined}
 */
const openCorrespondingSection = function(e) {
	e.preventDefault();
	const $currentSection = $('.accordion__section.is-active')
	const $activatedSection = $(this).parents('.accordion__section');
	const $activatedBody = $('.accordion__body', $activatedSection);
	const $activatedSectionTrigger = $('.accordion__head a', $activatedSection);
	const isActiveSectionClicked = $currentSection.get(0) === $activatedSection.get(0);

	closeSections();
	if (isActiveSectionClicked) return;

	$activatedSection.addClass('is-active');
	$activatedBody.slideDown(SLIDE_DELAY);
};

$(document).on('click', '.js-faq-accordion .accordion__head', openCorrespondingSection);
