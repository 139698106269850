/**
 * Equalize Height
 */

 $.fn.equalizeHeight = function() {
    var maxHeight = 0, itemHeight;

    this.height('');

    for (var i = 0; i < this.length; i++) {
        itemHeight = $(this[i]).height('auto').height();
        if (maxHeight < itemHeight) {
            maxHeight = itemHeight;
        }
    }

    return this.height(maxHeight);
};
