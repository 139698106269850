import { $body } from '../utils/globals';

const $navTrigger = $('.js-nav-trigger');
const $nav = $('.js-nav');
const SLIDE_DELAY = 1000;

$navTrigger.on('click', toggleNav);

function toggleNav(e) {
	$body.toggleClass('nav-opened');
	$nav.toggleClass('is-active');
	$navTrigger.toggleClass('is-active');
}

$('a', $nav).on('click', function(e) {
	const $currentLink = $(this);
	const $currentSubmenu = $currentLink.next('.sub-menu');

	if (!$currentSubmenu.length) return;
	if (!$nav.hasClass('is-active')) return;

	e.preventDefault();

	if ($currentLink.hasClass('clicked')) {
		window.location.href = $currentLink.attr('href');
	} else {
		$currentSubmenu.slideDown();
		$currentLink.addClass('clicked');
	}
});
