const _element = '.js-section-image-and-content';
const $element = $( _element );

if ( $element.length ) {
	const $window = $(window);
	const $body = $('body');

	$body.addClass('has-sticky-section');

	let _arrOffseTop = [];
	let _scroll      = 0;

	setOffsets();
	getActive();
	//addSliderOnMobile();

	$window.on( 'load resize orientationchange', function() {
		setOffsets();
	});

	$window.on( 'scroll', function() {
		_scroll = $window.scrollTop();

		getActive();
	});

	/*function addSliderOnMobile() {
		$element.find( '.js__slider' ).each( function() {
			$(this).slick({
				dots: true,
				arrows: false,
				infinite: true,
				speed: 400,
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: true			
			});
		});	
	}*/

	function getActive() {
		let _active = 0;

		const _all = _arrOffseTop.length;

		$.each(_arrOffseTop, function( _index, _value ) {
			if ( _scroll >= _value ) {
				_active = _index;
			}
		});

		$element.find( '.js__image > *' ).eq( _active ).addClass( 'active' ).siblings().removeClass( 'active' );
	}

	function setOffsets() {
		const _window = parseInt($window.height(), 10);

		$element.find( '.js__group > *' ).each( function( i ) {
			const $this   = $(this);
			const _offset = parseInt($this.offset().top, 10);
			const _height = parseInt($this.innerHeight() / 2, 10);

			_arrOffseTop[ i ] = ( _offset + _height - _window > 0 ) ? _offset + _height - _window : 0;
		});
	}
}
