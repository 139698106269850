const $subscribeField = $('.js-subscribe').find('.subscribe__field');
const $input = $subscribeField.find('input');

$input.on('focus', function() {
	$subscribeField.addClass('is-focused');

	$(this).on('blur', function() {
		const value = $(this).val();

		
		if (value === '') {
			$subscribeField.removeClass('is-focused');
		}
	});
});

const $progressbar = $('.gf_progressbar .gf_progressbar_percentage.percentbar_33');
const $radioButtons = $('.apply_wrapper.gform_wrapper.gform_wrapper.gform_wrapper.gravity-theme .custom-radios');

$radioButtons.on('click', function(event) {
	if($('input:radio:checked').length > 0){
		$progressbar.addClass('is-completed');
	}

	else {
    	$progressbar.removeClass('is-completed');
	}
});
