import { $win } from '../utils/globals'
import Isotope from 'isotope-layout';

$('.section-journal').each(() => {
	$win.on('load', () => {
		const items = document.querySelector('.journals__items');

		const isotope = new Isotope( items, {
			itemSelector: '.journals__item',
		});
	});
});
