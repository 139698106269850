import 'jquery.marquee';
import { $win } from '../utils/globals'

$win.on('load', function() {
		$('.section-logos-slider .section__logos').marquee({
		duration: 27000,
		gap: 0,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: true,
		startVisible: true
	});
});


/**
	Ticker
**/

/*const tickers = document.querySelectorAll('.js-ticker');

if (tickers.length > 0) {

	window.addEventListener('load', () => {
		tickers.forEach((ticker) => {
			createTicker(ticker);
		});
	});

	function createTicker(ticker) {
		const tickerContainer = ticker.querySelector('.ticker__inner');
		const tickerItems = ticker.querySelector('ul');
		let partnersWidth = tickerItems.offsetWidth;
		console.log(tickerContainer);

		tickerContainer.prepend(tickerItems.cloneNode(true));
		tickerContainer.prepend(tickerItems.cloneNode(true));
		tickerContainer.append(tickerItems.cloneNode(true));

		tickerContainer.style.setProperty('--item-width', `${-(partnersWidth)}px`);
		ticker.classList.add('is-animated');
	}
}*/

